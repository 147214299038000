import { FeatureListItem } from "../feature-list-item/feature-list-item";
import "./desktop-app.css";
export function DesktopApp() {
  return (
    <div className="desktop-app">
      <div>
        <div className="featured-title">Desktop App</div>
        <div className="featured-description">
          Easy automated synchronization between your computer and video server
          - Available for Windows and MacOS
        </div>
        <div>
          <ul>
            <li className="list-item">
              <FeatureListItem
                title="Sync Videos -"
                description="Upload, Transform, Encrypt and Share your videos"
              />
            </li>
            <li className="list-item">
              <FeatureListItem
                title="Manage Licences -"
                description="Generate Licences based on ACL, View Duration and number of devices"
              />
            </li>
            <li className="list-item">
              <FeatureListItem
                title="Video Metadata -"
                description="Add metadata to your videos to share discription of content with your viewers"
              />
            </li>
            <li className="list-item">
              <FeatureListItem
                title="Analytics -"
                description="Manage your viewers list, view history and content monitization"
              />
            </li>
          </ul>
        </div>
      </div>
      <img
        src="./desktop-image.png"
        alt="education cdn"
        className="featured-image"
      />
    </div>
  );
}
