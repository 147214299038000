import "./education.css";
import { AppFeatures } from "../app-features/app-features";
import { Header } from "../banner/header/header";
import { EducationBanner } from "../education-banner/education-banner";
import { Footer } from "../footer/footer";
import { LiveClassroom } from "../live-classroom/live-classroom";
import { ManageApp } from "../manage-app/manage-app";
import { MultiTeacher } from "../multi-teacher/multi-teacher";
import { ProtectApp } from "../protect-app/protect-app";
import { SellCourses } from "../sell-courses/sell-courses";
import { UniqueDesign } from "../unique-design/unique-design";
import { useEffect } from "react";

export function Education() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="education">
      <Header active="education" />
      <EducationBanner />
      <ProtectApp />
      <AppFeatures />
      <UniqueDesign />
      <LiveClassroom />
      <SellCourses />
      <MultiTeacher />
      <ManageApp />
      <Footer />
    </div>
  );
}
