import { FeatureListItem } from "../feature-list-item/feature-list-item";
import "./live-classroom.css";
export function LiveClassroom() {
  return (
    <div className="live-classroom">
      <div className="live-classroom-left">
        <div className="featured-title">Live Classroom</div>
        <div>
          <ul>
            <li className="list-item">
              <FeatureListItem description="Are you already teaching on Zoom? Integrate zoom within your own app" />
            </li>
            <li className="list-item">
              <FeatureListItem description="Share classes to your students after live session" />
            </li>
            <li className="list-item">
              <FeatureListItem description="Securely share Zoom Link with students" />
            </li>
          </ul>
        </div>
      </div>
      <img
        src="/live-classroom.png"
        alt="Live Classroom"
        className="live-classroom-img"
      ></img>
    </div>
  );
}
