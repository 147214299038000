import { FeatureListItem } from "../feature-list-item/feature-list-item";
import "./sell-courses.css";
export function SellCourses() {
  return (
    <div className="sell-courses">
      <img
        src="/sell-courses.png"
        alt="sell courses online"
        className="sell-courses-img"
      ></img>
      <div className="sell-courses-right">
        <div className="featured-title">Sell Courses</div>
        <div>
          <ul>
            <li className="list-item">
              <FeatureListItem description="Get course fee directly in your account" />
            </li>
            <li className="list-item">
              <FeatureListItem description="Support for selling courses on EMI" />
            </li>
            <li className="list-item">
              <FeatureListItem description="Instant activation of course on payment" />
            </li>
            <li className="list-item">
              <FeatureListItem description="Integrate your own payment mechanism" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
