import { GetDemoButton } from "../get-demo-button/get-demo-button";
import { BannerTitle } from "./banner-title/banner-title";
import "./banner.css";
import { FeatureButtons } from "./feature-buttons/feature-buttons";
import { Header } from "./header/header";
export function Banner() {
  return (
    <div class="banner">
      <Header active="home" />
      <div className="banner-content">
        <BannerTitle />
        <GetDemoButton />
        <FeatureButtons />
      </div>
    </div>
  );
}
