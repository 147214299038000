import "./App.css";
import { Home } from "./home/home";
import { useScript } from "./hooks/use-chat";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Education } from "./education/education";

function App() {
  useScript(
    "https://salesiq.zoho.com/widget",
    "18a7dcf42cff2372d4af5786c9debfed6d7a6af3e2729f5a55d3adaee01ae90c"
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/education" element={<Education />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
