import "./manage-app.css";
export function ManageApp() {
  return (
    <div className="manage-app">
      <div className="manage-app-title">
        Manage Courses, videos, live classrooms all from single app
      </div>
      <div className="manage-app-desc">
        With mobile application you get desktop application, which helps you
        manage courses, encrypt and upload your encrypted videos, Go Live,
        Manage students list, track payments all via single desktop app. This
        helps you focus more on teaching and less on managing app.
      </div>
      <img
        src="/manage-app.png"
        alt="Course Management App"
        className="manage-app-img"
      ></img>
    </div>
  );
}
