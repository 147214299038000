import "./player-features.css";
export function PlayerFeature(props) {
  return (
    <div className="player-feature">
      <div className="feature-image-container">
        <img className="feature-image" src={props.image} alt={props.alt} />
      </div>
      <div className="feature-text">{props.children}</div>
    </div>
  );
}
