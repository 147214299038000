import "./feature-button.css";

export function FeatureButton(props) {
  return (
    <div className="feature-button-container">
      <div>
        <img
          className="feature-button-image"
          src={props.image}
          alt={props.alt}
        />
      </div>
      <div className="feature-button-text">{props.text}</div>
    </div>
  );
}
