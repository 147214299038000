import { GetDemoButton } from "../get-demo-button/get-demo-button";
import "./get-demo.css";

export function GetDemo() {
  return (
    <div className="get-demo-container">
      <div className="get-demo">
        <div className="get-demo-title">
          Take your video experience to the next level
        </div>
        <GetDemoButton />
      </div>
    </div>
  );
}
