import "./multi-teacher.css";
import { FeatureListItem } from "../feature-list-item/feature-list-item";
export function MultiTeacher() {
  return (
    <div className="multi-teacher">
      <img
        src="/multi-teacher-img.png"
        alt="Multiteacher App Design"
        className="multi-teacher-img"
      ></img>
      <div className="unique-design-right">
        <div className="multi-featured-title">Multi-Teacher Support</div>
        <div className="multi-teacher-features">
          <ul>
            <li className="multi-teacher-list-item">
              <FeatureListItem description="Do you run coaching - Onboard all teachers on single app" />
            </li>
            <li className="multi-teacher-list-item">
              <FeatureListItem description="Looking to build Edutech marketplace, our dev and support teams are here to help you." />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
