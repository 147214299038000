import "./header.css";
import { Logo } from "./logo/logo";
import { Navigation } from "./navigation/navigation";
export function Header(props) {
  return (
    <div className="header">
      <Logo />
      <Navigation active={props.active} />
    </div>
  );
}
