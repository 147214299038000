import "./get-demo-button.css";
export function GetDemoButton() {
  return (
    <button
      className="get-demo-button"
      onClick={() => {
        window.$zoho.salesiq.floatwindow.open();
      }}
    >
      Get Demo
    </button>
  );
}
