import "./unique-design.css";
import { FeatureListItem } from "../feature-list-item/feature-list-item";
export function UniqueDesign() {
  return (
    <div className="unique-design">
      <img
        src="/unique-design-img.png"
        alt="Unique Education App Design"
        className="unique-design-img"
      ></img>
      <div className="unique-design-right">
        <img
          src="/unique-design-desc.png"
          alt="Unique Design"
          className="unique-design-desc"
        />
        <div className="featured-title">Unique Design</div>
        <div>
          <ul>
            <li className="list-item">
              <FeatureListItem description="Stand out with your own unique design and app theme" />
            </li>
            <li className="list-item">
              <FeatureListItem description="Share your custom feature request - our solution experts can build for you" />
            </li>
            <li className="list-item">
              <FeatureListItem
                title="Test Series"
                description="Showcase your highlights / achievements 
                on App"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
