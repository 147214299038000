import { FeatureListItem } from "../feature-list-item/feature-list-item";
import "./app-features.css";
export function AppFeatures() {
  return (
    <div className="app-features">
      <div className="app-features-left">
        <div className="featured-title">App Features</div>
        <div>
          <ul>
            <li className="list-item">
              <FeatureListItem
                title="QR Code based video"
                description="Make your teaching interactive by adding video QR code in your study materials or books"
              />
            </li>
            <li className="list-item">
              <FeatureListItem
                title="Realtime QnA"
                description="Help students ask questions whenever they are stuck / need your help via our QnA module"
              />
            </li>
            <li className="list-item">
              <FeatureListItem
                title="Test Series"
                description="Conduct online test series to help evaluate your students"
              />
            </li>
            <li className="list-item">
              <FeatureListItem
                title="Share PDFs and Study Material"
                description=" Share completely encrypted study materials with your students on your application."
              />
            </li>
          </ul>
        </div>
      </div>
      <img
        src="/app-features.png"
        alt="QR Code scanner"
        className="app-features-img"
      ></img>
    </div>
  );
}
