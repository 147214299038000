import { FeatureListItem } from "../feature-list-item/feature-list-item";
import "./delivery-cloud.css";
export function DeliveryCloud() {
  return (
    <div className="delivery-cloud">
      <img
        src="./video-delivery-img.png"
        alt="education cdn"
        className="cloud-featured-image"
      />
      <div>
        <div className="cloud-featured-title">
          Video Delivery and Storage Cloud
        </div>
        <div>
          <ul>
            <li className="cloud-list-item">
              <FeatureListItem
                title="Optimised for Streaming -"
                description="We use state of art streaming protocols to deliver smooth
                playback and high quality streaming"
              />
            </li>
            <li className="cloud-list-item">
              <FeatureListItem
                title="Realtime Reports -"
                description="We provide you realtime reports to monitor bandwith usage and
                video watch logs"
              />
            </li>
            <li className="cloud-list-item">
              <FeatureListItem
                title="End to End Encryption -"
                description="We store only encrypted videos, even we can't access your
                encrypted videos"
              />
            </li>
            <li className="cloud-list-item">
              <FeatureListItem
                title="Fraud Detection -"
                description="Fraud detection with device fingerprinting and usage patterns"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
