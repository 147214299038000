import { useNavigate } from "react-router-dom";
import "./navigation.css";
export function Navigation(props) {
  const navigate = useNavigate();
  return (
    <div className="navigation">
      <div
        onClick={() => navigate("/")}
        className={
          props.active === "home"
            ? "navigation-item selected"
            : "navigation-item"
        }
      >
        Home
      </div>
      <div
        onClick={() => navigate("/education")}
        className={
          props.active === "education"
            ? "navigation-item selected"
            : "navigation-item"
        }
      >
        Grepvideos for Education
      </div>
    </div>
  );
}
