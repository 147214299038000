import "./players.css";
import { PlayerFeature } from "./player-features/player-features";
export function Players() {
  return (
    <div className="players">
      <div className="offering">What we offer</div>
      <div className="heading">
        <div className="title">Mobile and Desktop Video Players</div>
        <div className="subtitle">
          Our specialized video players for mobile and desktop provides support
          for
        </div>
      </div>
      <div className="player-features">
        <div className="feature-section">
          <PlayerFeature image="./encryption.png">
            Play AES Encrypted videos with secure key transfer
          </PlayerFeature>
          <PlayerFeature image="./watermarking.png">
            Dynamic watermarking to discourage screen recording from external
            cameras
          </PlayerFeature>
        </div>

        <div className="feature-section">
          <img
            src="./players.png"
            alt="Mobile and Desktop Players"
            className="players-image"
          ></img>
          <PlayerFeature image="./application.png">
            Specialized Application for managing videos
          </PlayerFeature>
        </div>

        <div className="feature-section">
          <PlayerFeature image="./streaming.png">
            Streaming capability for buffer free playing of videos
          </PlayerFeature>
          <PlayerFeature image="./screenshot.png">
            Protection from decompilation of app, screen recording and screen
            mirroring
          </PlayerFeature>
        </div>
      </div>

      <div className="player-features-mobile">
        <div className="feature-section">
          <img
            src="./players.png"
            alt="Mobile and Desktop Players"
            className="players-image"
          ></img>
        </div>

        <div className="non-image-features">
          <div className="feature-section-mobile">
            <PlayerFeature image="./application.png">
              Specialized Application for managing videos
            </PlayerFeature>
          </div>
          <div className="feature-section-mobile">
            <PlayerFeature image="./encryption.png">
              Play AES Encrypted videos with secure key transfer
            </PlayerFeature>
          </div>
          <div className="feature-section-mobile">
            <PlayerFeature image="./watermarking.png">
              Dynamic watermarking to discourage screen recording from external
              cameras
            </PlayerFeature>
          </div>
          <div className="feature-section-mobile">
            <PlayerFeature image="./streaming.png">
              Streaming capability for buffer free playing of videos
            </PlayerFeature>
          </div>
          <div className="feature-section-mobile">
            <PlayerFeature image="./screenshot.png">
              Protection from decompilation of app, screen recording and screen
              mirroring
            </PlayerFeature>
          </div>
        </div>
      </div>
    </div>
  );
}
