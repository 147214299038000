import { FeatureListItem } from "../feature-list-item/feature-list-item";
import "./protect-app.css";
export function ProtectApp() {
  return (
    <div className="protect-app">
      <img
        src="/protect-app.png"
        alt="app encryption"
        className="protect-app-img"
      ></img>
      <div className="protect-app-right">
        <div className="featured-title">Protect your app</div>
        <div>
          <ul>
            <li className="list-item">
              <FeatureListItem description="End to end encryption - Only you and your authorized students can watch video" />
            </li>
            <li className="list-item">
              <FeatureListItem description="Protect app from screen recording, projecting, decompile safe app" />
            </li>
            <li className="list-item">
              <FeatureListItem description="DRM Enabled Video Player" />
            </li>
            <li className="list-item">
              <FeatureListItem description="Unified Desktop and Mobile Video Player" />
            </li>
          </ul>
        </div>
        <div className="featured-description">
          Do you already have an app? Get Grepvideos Player SDK and add security
          to your existing app
        </div>
      </div>
    </div>
  );
}
