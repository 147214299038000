import { Banner } from "../banner/banner";
import { DeliveryCloud } from "../delivery-cloud/delivery-cloud";
import { DesktopApp } from "../desktop-app/desktop-app";
import { Footer } from "../footer/footer";
import { GetDemo } from "../get-demo/get-demo";
import { Players } from "../mobile-desktop-player/players";
import { useEffect } from "react";

export function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Banner />
      <Players />
      <DeliveryCloud />
      <DesktopApp />
      <GetDemo />
      <Footer />
    </div>
  );
}
