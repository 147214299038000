import { Logo } from "../banner/header/logo/logo";
import { useNavigate } from "react-router-dom";

import "./footer.css";
export function Footer() {
  let navigate = useNavigate();

  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-logo-section">
          <Logo />
          <div className="footer-description">
            Grepvideos is video distribution technology company. We help content
            creators to monitise their content and build their brand online
          </div>
        </div>
        <div className="footer-right">
          <div className="footer-section">
            <div className="footer-title">Info</div>
            <div>
              <div
                onClick={() => {
                  navigate("/");
                }}
                className="footer-link"
              >
                Home
              </div>
              <div
                onClick={() => {
                  navigate("/education");
                }}
                className="footer-link"
              >
                Grepvideos for Education
              </div>
            </div>
          </div>
          <div className="footer-section">
            <div className="footer-title">Policy</div>
            <div>
              <div className="footer-link">Privacy Policy</div>
              <div className="footer-link">Terms of Service</div>
            </div>
          </div>
          <div className="footer-section">
            <div className="footer-title">Contact Us</div>
            <div>
              <div className="footer-link">info@grepvideos.com</div>
              <div className="footer-link">+91-7597517575</div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">@2022 Grepvideos Pvt Ltd.</div>
    </div>
  );
}
