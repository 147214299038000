import "./banner-title.css";
export function BannerTitle() {
  return (
    <div className="banner-title">
      <div className="banner-title-text">Video Distribution Technology</div>
      <div className="sub-title">
        Grepvideos provides you end to end solution for distributing your
        premium content. With grepvideos video technology you get end to end
        video distribution infra, with security layer across services
      </div>
    </div>
  );
}
