import { FeatureButton } from "./feature-button";
import "./feature-button.css";

export function FeatureButtons() {
  return (
    <div className="feature-buttons-container">
      <FeatureButton
        text="Mobile Video Player"
        alt="mobile sdk"
        image="./mobile_player.png"
      />
      <FeatureButton
        text="Desktop Video Player"
        alt="Dektop Player SDK"
        image="./desktop_player.png"
      />
      <FeatureButton
        text="Desktop App"
        alt="Desktop Management App"
        image="./desktop_app.png"
      />
      <FeatureButton
        text="Video Delivery and Storage Cloud"
        alt="Video CDN Cloud"
        image="./cloud.png"
      />
    </div>
  );
}
