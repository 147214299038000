import { GetDemoButton } from "../get-demo-button/get-demo-button";
import "./education-banner.css";
export function EducationBanner() {
  return (
    <div>
      <div className="education-banner-bg"></div>
      <div className="education-banner">
        <div className="education-banner-left-section">
          <div className="education-banner-title">
            Get your own branded online learning app
          </div>
          <div className="education-banner-description">
            Build online cum offline experience for your students, through your
            own branded learning
          </div>
          <GetDemoButton />
        </div>
        <div className="education-banner-right-section">
          <img
            src="/education-header.png"
            alt="online learning app"
            className="education-header-img"
          />
        </div>
      </div>
    </div>
  );
}
