import "./feature-list-item.css";

export function FeatureListItem(props) {
  return (
    <div>
      <span className="list-item-title">{props.title} </span>
      <span className="list-item-description">{props.description}</span>
    </div>
  );
}
